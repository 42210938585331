'use client';

import { logSentryError } from 'shared/utils/logSentry';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Dictionaries, getSynergieConstructDictionary, Locale } from 'shared/i18n/i18n-config';
import AppLayout from '../components/AppLayout';
import { getLocaleFromPathname } from 'shared/utils/getLocale';
import ErrorPage from 'shared/pages/CustomError';

type Props = {
  error: Error & { digest?: string };
};

export default function CustomErrorPage({ error }: Props) {
  let errorMessage = 'internal-server-error';
  if (error && error.message) errorMessage = error.message;
  const pathname = usePathname();
  const locale: Locale = pathname ? getLocaleFromPathname(pathname) : 'nl';
  const [dictionary, setDictionary] = useState<Partial<Dictionaries> | null>(null);
  useEffect(() => {
    const getDictionary = async () => {
      try {
        setDictionary(await getSynergieConstructDictionary(locale));
      } catch (error) {
        console.error('cannot get dictionary:', error);
      }
    };

    getDictionary();
  }, [locale]);

  useEffect(() => {
    // Log the error to Sentry
    logSentryError(error);
  }, [error]);
  if (!dictionary) return <>{errorMessage}</>;

  return (
    <AppLayout
      availableLocales={[
        { locale: 'nl', pathname: `/something-went-wrong/` },
        { locale: 'fr', pathname: `/something-went-wrong/` },
      ]}
      dictionary={dictionary}
    >
      <ErrorPage dictionary={dictionary} />
    </AppLayout>
  );
}
